import Button from '@mui/material/Button';
import useLogic from './logic';
import S from './style';
import type { Props } from './types';
import { Typography } from '@mui/material';
import { Loader } from '../../components';

const Bots = (props: Props) => {
    const { disconnectLoading, discordBotConnected, loading, onClickAdd, onClickDisconnect } = useLogic(props);
    if (loading) return <Loader />;

    const renderBotData = () => (
        <S.Section>
            <Typography variant="body1" color="text.secondary">
                Bot is currently linked
            </Typography>
            <div>
                <Button color="primary" disabled={disconnectLoading} onClick={onClickDisconnect} variant="contained">
                    Unlink
                </Button>
            </div>
        </S.Section>
    );

    const renderConnectionSection = () => (
        <S.Section>
            <Typography variant="body1" color="text.secondary">
                To install bot you need to:
            </Typography>
            <ol>
                <li>
                    <Typography variant="body1" color="text.secondary">
                        Install bot to your Discord
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1" color="text.secondary">
                        Use <b>/link</b> command in the bot to link it to this application
                    </Typography>
                </li>
            </ol>
            <div>
                <Button color="primary" onClick={onClickAdd} variant="contained">
                    Install bot to Discord
                </Button>
            </div>
        </S.Section>
    );

    const renderCommunity = () => (
        <S.Section>
            <Typography variant="body1" component="div" color="text.secondary">
                Check our Discord community! In this community you can:
                <ul>
                    <li> Find updates and news about our service</li>
                    <li> Learn how to generate images more effectively</li>
                    <li>See other people works</li>
                </ul>
            </Typography>

            <a href="https://discord.gg/UUbUjHvkD2" target="_blank">
                <Button color="primary" variant="contained">
                    Join our community
                </Button>
            </a>
        </S.Section>
    );

    return (
        <S.Container>
            <S.Card>
                <S.CardContent>
                    <S.Header>
                        <S.Icon src="/discord.svg" />
                        <Typography variant="h5" component="div">
                            Discord
                        </Typography>
                    </S.Header>
                    {discordBotConnected ? renderBotData() : renderConnectionSection()}
                    <S.Divider />
                    {renderCommunity()}
                </S.CardContent>
            </S.Card>
        </S.Container>
    );
};

export default Bots;

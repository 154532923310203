import { FormMode, FormTab } from '../../types';
import * as S from './style';

const ModeSelection = (props: { setMode: (mode: FormMode) => void; setTab: (tab: FormTab) => void }) => {
    return (
        <S.Container>
            <S.Card
                onClick={() => {
                    props.setMode(FormMode.apparelToImage);
                    props.setTab('apparel');
                }}
            >
                <S.CardImage url="/assets/apparel-to-image-preview.png">
                    <S.ApparelImage url="/assets/apparel-to-image-apparel.png" />
                </S.CardImage>
                <S.CardTitle>{'Apparel -> Photo Shoot'}</S.CardTitle>
            </S.Card>
            <S.Card
                onClick={() => {
                    window.location.href = 'https://studio.hautech.ai';
                }}
            >
                <S.PromtContainer>
                    <S.Prompt>
                        <S.PromptHeader>Text prompt:</S.PromptHeader>
                        Urban scene set in Manchester during spring, featuring iconic city buildings in the background.
                        Two models in the foreground: the first is of European descent with brown hair and light eyes,
                        the second of African descent with short hair and a captivating smile...
                    </S.Prompt>
                </S.PromtContainer>
                <S.CardTitle>{'Text -> Image'}</S.CardTitle>
            </S.Card>
            <S.Card
                onClick={() => {
                    props.setMode(FormMode.photoEditor);
                    props.setTab('photo');
                }}
            >
                <S.CardImage url="/assets/photo-editor-preview.png" />
                <S.CardTitle>{'Photo Editor'}</S.CardTitle>
            </S.Card>
        </S.Container>
    );
};

export default ModeSelection;

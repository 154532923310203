import 'react-material-symbols/rounded';

import Auth from './Auth';
import AuthCallback from './AuthCallback';
import BillingCallback from './BillingCallback';
import Bots from './Bots';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Comparison from './Comparison';
import * as Layout from './Layout';
import Subscriptions from './Subscriptions';
import ConnectCallback from './ConnectCallback';
import ProvidersWrapper from './providers';
import { GlobalStyles } from '@mui/material';
import StackEditor from './Stacks/StackEditor';
import { StudioBanner } from '../components/StudioBanner';

const Application = () => (
    <ProvidersWrapper>
        <GlobalStyles styles={(theme) => ({ body: { background: theme.palette.layout.surface.low } })}></GlobalStyles>
        <Routes>
            <Route
                path="/"
                element={
                    <Auth>
                        <Outlet />
                    </Auth>
                }
            >
                {/* <Route index element={<Navigate to="/stacks" />} /> */}
                <Route path="/" element={<Layout.Application />}>
                    <Route path="/view/:stackId" Component={StackEditor} />
                    <Route path="/getting-started" element={<Navigate to="/" />} />
                </Route>
                <Route path="/" element={<Layout.Pages />}>
                    <Route path="/subscriptions" Component={Subscriptions} />
                    <Route path="/bots" Component={Bots} />
                    <Route path="/comparison/:id" Component={Comparison} />
                </Route>
            </Route>

            <Route path="/auth/callback" Component={AuthCallback} />
            <Route path="/billing/cancelled" Component={BillingCallback} />
            <Route path="/billing/paid" Component={BillingCallback} />
            <Route path="/connect" Component={ConnectCallback} />
        </Routes>
    </ProvidersWrapper>
);

export default Application;
